import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/locales";
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: {
            title: i18n.t('menu.home')
        }
    },
    {
        path: '/team',
        name: 'Team',
        component: () => import('@/views/Team'),
        meta: {
            title: i18n.t('menu.team')
        }
    },
    {
        path: '/case',
        name: 'Case',
        component: () => import('@/views/Case'),
        meta: {
            title: i18n.t('menu.case')
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About'),
        meta: {
            title: i18n.t('menu.about')
        }
    }
]
const router = new VueRouter({
    mode: 'hash',
    'exact-active-class': 'active',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title+`-${i18n.t('name')}`
    }
    next();
})
export default router
