const thousands = function (value) {
  // console.log(value);
  if (!value) return '0'
  var valueNew=value.toString().split('.');
  valueNew[0]=(valueNew[0] || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return valueNew.join('.');
}

const percent = function (value) {
  if (!value) return '0'
  return value * 100
}

const min=function(value){
  if (!value) return '0'
  return Math.floor(value/60)+'′'+(value%60);
}


/**
 * 如果值不存，返回默认文本
 * @param {String} string
 */
export function emptyValue(val, text = '-') {
  if (val === undefined || val === null) {
    return text
  }
  return val
}

/**
 * 格式化填报值
 * @param val
 * @param utils
 * @returns {number|*}
 */
export function fillValue(val, utils, decimal = 2) {
  if (val === '' || val === null || val === undefined) {
    return val
  }
  // 元转换
  if (val !== null && utils === '元') {
    var newNum = Number(Number(val).toFixed(decimal)).toLocaleString('zh', { minimumFractionDigits: decimal })
    return newNum
  }
  // 超过万 元转换
  if (val !== null && utils === '万元') {
    // const moneyUnits = ['元', '万元', '亿元']
    // dividend- 10000, 100000000, 100000000
    const dividend = 10000
    // 转换单位
    return (parseFloat(val) / dividend).toFixed(2)
    // let currentNum = val
    // // 转换数字
    // let currentUnit = null
    // for (var i = 0; i < 4; i++) {
    //   currentUnit = moneyUnits[i]
    //   if (strNumSize(currentNum) < 5) {
    //     break
    //   }
    //   currentNum = currentNum / dividend
    // }
    // var m = { num: 0, unit: '' }
    // m.num = currentNum.toFixed(2)
    // m.unit = currentUnit
    // return m.num
  }
  // 吨标煤/万元 转换
  if (val !== null && utils === '吨标煤/万元') {
    return parseFloat(val).toFixed(4)
  }
  // 人/人次 转换
  if (val !== null && (utils === '人' || utils === '人次')) {
    return parseInt(val).toFixed(0)
  }
  // 其他数值小数点精确后两位
  if (String(val).indexOf('.') !== -1) {
    return parseFloat(val).toFixed(decimal)
  }
  return parseFloat(val).toFixed(decimal)
}

export default {
  thousands, percent,min,emptyValue,fillValue
}
