<template>
<div class="footer-box" :class="{'is-home':isHome}">
  <div class="container">
    <p>@2021-2025 上海风物风清企业管理合伙企业(有限合伙) </p>
    <p><a href="https://beian.miit.gov.cn/">沪ICP备2021008312号-1</a></p>
  </div>
</div>
</template>

<script>
export default {
  name: "CommFooter",
  computed:{
    isHome(){
      return this.$route.path === '/';
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-box{
  text-align: center;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  padding-top: 35px;
  padding-bottom: 40px;
  a{
    color: #666666;
  }
  &.is-home{
    padding-bottom: 120px;
  }

}
</style>
