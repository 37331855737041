<template>
  <div id="app">
    <rem-container :min-width="1650">
      <comm-header />
      <router-view />
      <comm-footer />
    </rem-container>
  </div>
</template>
<script>

import CommHeader from "@/components/CommHeader";
import CommFooter from "@/components/CommFooter";
import RemContainer from "@/components/RemContainer";
export default {
  name: 'app',
  components: {RemContainer, CommFooter, CommHeader},
  data() {
    return {}
  },
  computed: {
  },
  created() {

  },
  mounted() {
  },
  beforeDestroy() {

  },
  methods: {
  },
  watch:{
  }
}
</script>
<style lang="scss">
#app {
}
</style>
