import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang:localStorage.getItem('lang') || 'zh',
    globalScale:1,
    getTimeKey:new Date().getTime(),
    startVal:0,
    durationAnimate:2000
  },
  mutations: {
    changeGlobalScale (state, scale) {
      state.globalScale = scale;
    },
    changeTimeKey (state,timeKey) {
      state.getTimeKey = timeKey
    },
    changeLang (state, lang) {
      state.lang = lang;
      localStorage.setItem('lang', lang)
    },
  },
  actions: {

  },
  modules: {
  }
})
