import VueI18n from "vue-i18n";
import Vue from 'vue'
import zh from "./lang/zh";
import en from "./lang/en";
Vue.use(VueI18n);
const messages = {
    en: en,
    zh: zh
};
const i18n = new VueI18n({
    locale: 'zh',
    messages
});
console.log(i18n);
i18n.locale = window.localStorage.getItem('lang') || 'zh';
export default i18n
