import axios from "axios"


let instance = axios.create({
    headers: {
        // 'content-type': 'application/x-www-form-urlencoded',
		//'X-Requested-With': 'XMLHttpRequest'
    }
})

//axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios.defaults.withCredentials=true;
// http request 拦截器
instance.interceptors.request.use(
    config => {
        return config
    },
    err => {
        return Promise.reject(err)
    });

// http response 拦截器
instance.interceptors.response.use(
    response => {
        // 错误处理
        if(response.data.code==-1){
            return Promise.reject(new Error(response.msg || 'Error'))
        }
        return response.data||response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })
export default instance

