import Vue from 'vue';
import {
    Carousel,
    CarouselItem,
    Row,
    Col,
    Image
} from 'element-ui';
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image)


