<template>
  <div class="header-box">
    <div class="container">
      <div class="website-name">
        <a href="/" class="logo"></a>
      </div>
      <div class="nav-box">
        <router-link v-for="item in navList" :key="item.name" :to="{path: item.path}" class="nav-item">{{ item.name }}
        </router-link>
      </div>
      <div class="lange" v-if="lang==='zh'">
        <span>中</span>
        <i class="lange-switch"/>
        <span class="other" @click="handlerSetLange('en')">EN</span>
      </div>
      <div class="lange" v-else>
        <span>EN</span>
        <i class="lange-switch"/>
        <span class="other" @click="handlerSetLange('zh')">中</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CommHeader",
  data() {
    return {
      navList: [
        {
          name: this.$t('menu.home'),
          path: '/'
        },
        {
          name: this.$t('menu.about'),
          path: '/about'
        },
        {
          name: this.$t('menu.team'),
          path: '/team'
        },
        {
          name: this.$t('menu.case'),
          path: '/case'
        },
      ]
    }
  },
  computed: {
    ...mapState(['lang'])
  },
  methods: {
    handlerSetLange(lang) {
      this.$store.commit('changeLang',lang)
      this.$i18n.locale = lang
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-box {

  background: #FFFFFF;

  .container {
    height: 80px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .website-name {
      display: inline-flex;
      flex: 1;

      .logo {
        display: block;
        width: 168px;
        height: 50px;
        background: url("./images/logo.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .nav-box {
      display: flex;

      > a.nav-item {
        font-size: 16px;
        color: #231916;
        line-height: 22px;
        margin-left: 56px;

        &:hover {
          color: #C8171E;
        }

        &.router-link-exact-active {
          font-weight: 600;
          font-size: 16px;
          color: #C8171E;
        }
      }
    }

    .lange {
      margin-left: 64px;
      display: flex;
      align-items: center;

      > span {
        color: #231916;
        font-size: 16px;
        cursor: pointer;

        &.other {
          color: #999999;
        }
      }

      .lange-switch {
        display: block;
        width: 12px;
        height: 12px;
        background: url("./images/switch-lan.png") no-repeat;
        background-size: 100% 100%;
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }
}
</style>
