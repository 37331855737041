export function debounce(delay, callback) {
    let lastTime

    return function () {
        clearTimeout(lastTime)

        const [that, args] = [this, arguments]

        lastTime = setTimeout(() => {
            callback.apply(that, args)
        }, delay)
    }
}



export  function timestampToDateFormat(timestamp) {
    const dateObj = new Date(timestamp); // 创建Date对象
    const year = dateObj.getFullYear(); // 获取年份
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // 获取月份，并补零
    const day = ("0" + dateObj.getDate()).slice(-2); // 获取日期，并补零

    return `${year}-${month}-${day}`; // 返回转换后的日期格式
}
