import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from "./utils/request"
import filters from '@/utils/filter'

Vue.config.productionTip = false
Vue.prototype.$axios = request
import i18n from '@/locales/index';
// import style
import '@/assets/scss/reset.scss'
import '@/assets/scss/public.scss'
import '@/utils/component';

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
